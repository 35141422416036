import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <div className="privacy-container">
                        <h1>Privacy Policy</h1>

                        <section>
                            <h2>1. Information We Collect</h2>
                            <p>We collect information you provide directly, including account details, profile information, and content you post. We also collect usage data and device information automatically.</p>
                        </section>

                        <section>
                            <h2>2. How We Use Your Information</h2>
                            <p>We use collected information to:
                                <ul>
                                    <li>Provide and improve our services</li>
                                    <li>Personalize your experience</li>
                                    <li>Process transactions</li>
                                    <li>Send notifications and updates</li>
                                    <li>Analyze usage patterns</li>
                                    <li>Prevent fraud and abuse</li>
                                </ul>
                            </p>
                        </section>

                        <section>
                            <h2>3. Information Sharing</h2>
                            <p>We may share information with:
                                <ul>
                                    <li>Other users (as per your privacy settings)</li>
                                    <li>Service providers and partners</li>
                                    <li>Legal authorities when required</li>
                                </ul>
                            </p>
                        </section>

                        <section>
                            <h2>4. Data Security</h2>
                            <p>We implement reasonable security measures to protect your information but cannot guarantee absolute security.</p>
                        </section>

                        <section>
                            <h2>5. Your Rights</h2>
                            <p>You have the right to:
                                <ul>
                                    <li>Access your personal data</li>
                                    <li>Correct inaccurate data</li>
                                    <li>Request deletion of your data</li>
                                    <li>Object to data processing</li>
                                    <li>Export your data</li>
                                </ul>
                            </p>
                        </section>

                        <section>
                            <h2>6. Cookies and Tracking</h2>
                            <p>We use cookies and similar technologies to improve user experience and analyze usage patterns.</p>
                        </section>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default PrivacyPolicy; 