import React from 'react';
import './DeleteModal.css';

const DeleteModal = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Delete Post</h2>
                <p>Are you sure you want to delete this post? This action cannot be undone.</p>
                <div className="modal-buttons">
                    <button 
                        className="delete-button"
                        onClick={onConfirm}
                    >
                        Delete
                    </button>
                    <button 
                        className="cancel-button"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteModal;
