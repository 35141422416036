import React, { useState } from 'react';
import { collection, addDoc, doc, getDoc, serverTimestamp, query, getDocs, where } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import './CreatePost.css';
import { slugify } from '../utils/slugify';
import RichTextEditor from './RichTextEditor';

const CreatePost = ({ communityName }) => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!user || !title.trim() || !content.trim()) {
            setError('Please fill in all fields');
            return;
        }

        try {
            setLoading(true);
            const postsRef = collection(
                db, 
                'communities', 
                communityName.toLowerCase(), 
                'posts'
            );

            const postData = {
                title: title.trim(),
                content: content.trim(),
                authorId: user.uid,
                createdAt: serverTimestamp(),
                isDeleted: false,
                isHtml: true,
                votes: [],
                voteCount: 0
            };

            const docRef = await addDoc(postsRef, postData);
            
            // Create URL-friendly slug from title
            const slug = title.trim()
                .toLowerCase()
                .replace(/[^a-z0-9]+/g, '-')
                .replace(/(^-|-$)/g, '');

            // Make sure to use the slug in the navigation
            navigate(`/c/${communityName}/posts/${docRef.id}/${slug}`);
        } catch (error) {
            console.error('Error creating post:', error);
            setError('Failed to create post. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="create-post-container">
            {error && (
                <div className="notification error">
                    {error}
                </div>
            )}
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    className="post-title-input"
                />
                <RichTextEditor
                    value={content}
                    onChange={setContent}
                    placeholder="What are your thoughts?"
                />
                <button 
                    type="submit" 
                    disabled={loading || !content.trim() || !title.trim()}
                    className="post-button"
                >
                    {loading ? 'Posting...' : 'Post'}
                </button>
            </form>
        </div>
    );
};

export default CreatePost;
