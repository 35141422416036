import React, { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { signInWithGoogle, sendEmailLink } from '../googleAuth';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './LoginPage.css';
import { useAuth } from '../AuthContext';

const LoginPage = () => {
    const { isAuthenticated, loading } = useAuth();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [isEmailSent, setIsEmailSent] = useState(false);
    const navigate = useNavigate();

    if (loading) {
        return <div>Loading...</div>;
    }

    if (isAuthenticated) {
        return <Navigate to="/dashboard" replace />;
    }

    const handleEmailSignIn = async (e) => {
        e.preventDefault();
        try {
            await sendEmailLink(email);
            setIsEmailSent(true);
            setError('');
        } catch (error) {
            setError(error.message);
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            await signInWithGoogle();
            navigate('/dashboard');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <>
            <Header hideAuthButton={true} />
            <div className="login-page">
                <div className="login-container">
                    {isEmailSent ? (
                        <div className="confirmation-message">
                            <h2>✉️ Check your email!</h2>
                            <p>We've sent a sign-in link to:</p>
                            <p className="email-address">{email}</p>
                            <p className="instructions">
                                Click the link in the email to sign in to your account.
                                <br />
                                Don't see it? Check your spam folder.
                            </p>
                            <button 
                                className="back-button"
                                onClick={() => {
                                    setIsEmailSent(false);
                                    setEmail('');
                                }}
                            >
                                Back to Login
                            </button>
                        </div>
                    ) : (
                        <>
                            <h2>Login</h2>
                            {error && <p className="error">{error}</p>}
                            
                            <form onSubmit={handleEmailSignIn}>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <button type="submit" className="email-button">
                                    Login with Email
                                </button>
                            </form>

                            <div className="divider">
                                <span>OR</span>
                            </div>

                            <button 
                                className="google-button"
                                onClick={handleGoogleSignIn}
                            >
                                <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google" />
                                Login with Google
                            </button>
                        </>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default LoginPage;
