import { db } from '../firebase';
import { 
    collection, 
    addDoc, 
    serverTimestamp,
    query,
    where,
    getDocs,
    getDoc,
    doc 
} from 'firebase/firestore';

export const sendMessage = async (senderId, recipientId, message) => {
    try {
        await addDoc(collection(db, 'messages'), {
            senderId,
            recipientId,
            message,
            participants: [senderId, recipientId],
            timestamp: serverTimestamp(),
            read: false
        });
        return true;
    } catch (error) {
        console.error('Error sending message:', error);
        throw error;
    }
};
export const getMessages = async (userId) => {
    try {
        // Create queries for both sent and received messages
        const sentQuery = query(
            collection(db, 'messages'),
            where('senderId', '==', userId)
        );
        
        const receivedQuery = query(
            collection(db, 'messages'),
            where('recipientId', '==', userId)
        );
        
        // Get both sent and received messages
        const [sentSnapshot, receivedSnapshot] = await Promise.all([
            getDocs(sentQuery),
            getDocs(receivedQuery)
        ]);
        
        // Combine and process all messages
        const messages = await Promise.all([
            ...sentSnapshot.docs,
            ...receivedSnapshot.docs
        ].map(async (docSnapshot) => {
            const messageData = docSnapshot.data();
            
            try {
                // Fetch sender data
                const senderDocRef = doc(db, 'users', messageData.senderId);
                const senderDocSnapshot = await getDoc(senderDocRef);
                const senderData = senderDocSnapshot.data();
                
                // Fetch recipient data
                const recipientDocRef = doc(db, 'users', messageData.recipientId);
                const recipientDocSnapshot = await getDoc(recipientDocRef);
                const recipientData = recipientDocSnapshot.data();
                
                return {
                    id: docSnapshot.id,
                    ...messageData,
                    senderName: senderData?.userProfile?.username || 'Unknown User',
                    senderImage: senderData?.userProfile?.profileImageUrl || null,
                    recipientName: recipientData?.userProfile?.username || 'Unknown User',
                    recipientImage: recipientData?.userProfile?.profileImageUrl || null
                };
            } catch (error) {
                console.error('Error getting user data:', error);
                return {
                    id: docSnapshot.id,
                    ...messageData,
                    senderName: 'Unknown User',
                    senderImage: null,
                    recipientName: 'Unknown User',
                    recipientImage: null
                };
            }
        }));
        
        // Sort messages by timestamp
        return messages.sort((a, b) => b.timestamp - a.timestamp);
    } catch (error) {
        console.error('Error getting messages:', error);
        throw error;
    }
};

