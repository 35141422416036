import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { DEFAULT_PROFILE_PHOTO } from '../utils/constants';
import Header from '../components/Header';
import Footer from '../components/Footer';
import AboutCommunity from '../components/AboutCommunity';
import Comments from '../components/Comments/Comments';
import PostVote from '../components/PostVote';
import ReportModal from '../components/ReportModal';
import DeleteModal from '../components/DeleteModal';
import './PostPage.css';
import { useAuth } from '../AuthContext';
import RichTextEditor from '../components/RichTextEditor';
import { formatDistanceToNow } from 'date-fns';

const PostPage = () => {
    const { communityName, postId, postSlug } = useParams();
    console.log('Params:', { communityName, postId, postSlug });
    const [post, setPost] = useState(null);
    const [community, setCommunity] = useState(null);
    const [authorUsername, setAuthorUsername] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [comments, setComments] = useState([]);
    const [authorProfilePhoto, setAuthorProfilePhoto] = useState('');
    const defaultAvatar = "https://firebasestorage.googleapis.com/v0/b/fencerstats.appspot.com/o/defaultprofile%2Fdefault_profile.png?alt=media";
    const commentsRef = useRef(null);
    const [showReportModal, setShowReportModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { user, isAuthenticated } = useAuth();
    const [isEditing, setIsEditing] = useState(false);
    const [editedContent, setEditContent] = useState('');

    const navigate = useNavigate();

    // Simple date formatter
    const formatDate = (date) => {
        if (!date) return '';
        return new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch community data using query instead of direct doc reference
                console.log('Fetching community:', communityName);
                const q = query(
                    collection(db, 'communities'),
                    where('nameToLower', '==', communityName.toLowerCase())
                );
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const communityDoc = querySnapshot.docs[0];
                    const communityData = {
                        id: communityDoc.id,
                        ...communityDoc.data()
                    };

                    // Fetch creator's username
                    if (communityData.createdBy) {
                        const creatorDoc = await getDoc(doc(db, 'users', communityData.createdBy));
                        if (creatorDoc.exists()) {
                            communityData.creatorUsername = creatorDoc.data().userProfile.username;
                        }
                    }

                    console.log('Community data:', communityData);
                    setCommunity(communityData);

                    // Fetch post data
                    console.log('Fetching post:', `communities/${communityName}/posts/${postId}`);
                    const postRef = doc(db, 'communities', communityName.toLowerCase(), 'posts', postId);
                    const postDoc = await getDoc(postRef);
                    console.log('Post exists:', postDoc.exists());
                    
                    if (postDoc.exists()) {
                        const postData = {
                            id: postDoc.id,
                            ...postDoc.data(),
                            createdAt: postDoc.data().createdAt?.toDate()
                        };
                        console.log('Post data:', postData);
                        setPost(postData);

                        // Fetch author's username and profile photo
                        console.log('Fetching author:', postData.authorId);
                        const userDocRef = doc(db, 'users', postData.authorId);
                        const userDoc = await getDoc(userDocRef);
                        console.log('User exists:', userDoc.exists());
                        if (userDoc.exists()) {
                            const userData = userDoc.data().userProfile;
                            setAuthorUsername(userData.username);
                            setAuthorProfilePhoto(userData.profileImageUrl);
                        }
                    } else {
                        console.log('Post not found');
                        setError('Post not found');
                    }
                } else {
                    console.log('Community not found');
                    setError('Community not found');
                }
            } catch (err) {
                console.error('Error details:', err);
                setError('Error loading data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [communityName, postId]);

    useEffect(() => {
        if (!postId || !communityName) return;

        const commentsRef = collection(db, 'communities', communityName.toLowerCase(), 'posts', postId, 'comments');
        const q = query(commentsRef);
        
        const unsubscribe = onSnapshot(q, async (snapshot) => {
            try {
                // Fetch user data for each comment
                const commentsWithUserData = await Promise.all(
                    snapshot.docs.map(async (commentDoc) => {
                        const commentData = commentDoc.data();
                        // Use the imported doc function with db
                        const userDocRef = doc(db, 'users', commentData.authorId);
                        const userDoc = await getDoc(userDocRef);
                        const userData = userDoc.exists() ? userDoc.data().userProfile : null;
                        
                        return {
                            id: commentDoc.id,
                            ...commentData,
                            authorProfilePhoto: userData?.profileImageUrl || DEFAULT_PROFILE_PHOTO,
                            authorUsername: userData?.username
                        };
                    })
                );
                setComments(commentsWithUserData);
            } catch (error) {
                console.error('Error fetching comments:', error);
            }
        });

        return () => unsubscribe();
    }, [postId, communityName]);

    useEffect(() => {
        if (post) {
            setEditContent(post.content);
        }
    }, [post]);

    const getCommentsLabel = (count) => {
        if (count === 0) return '0 Comments';
        if (count === 1) return '1 Comment';
        return `${count} Comments`;
    };

    const scrollToComments = () => {
        if (commentsRef.current) {
            commentsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleDeletePost = async () => {
        try {
            const postRef = doc(db, 'communities', communityName.toLowerCase(), 'posts', postId);
            await updateDoc(postRef, {
                isDeleted: true
            });
            setShowDeleteModal(false);
            // Refresh the post data
            setPost(prev => ({
                ...prev,
                isDeleted: true
            }));
        } catch (error) {
            console.error('Error deleting post:', error);
        }
    };

    const handleSaveEdit = async () => {
        try {
            const postRef = doc(db, 'communities', communityName.toLowerCase(), 'posts', postId);
            const now = new Date();
            await updateDoc(postRef, {
                content: editedContent,
                lastEdited: now
            });
            
            setPost(prev => ({
                ...prev,
                content: editedContent,
                lastEdited: now
            }));
            
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating post:', error);
        }
    };

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error">{error}</div>;
    if (!post || !community) return <div className="not-found">Content not found</div>;

    console.log('PostPage rendering with:', { postId, communityName, post });

    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <div className="post-page-container">
                        <div className="post-column">
                            <div className="back-nav">
                                <button 
                                    className="back-button" 
                                    onClick={() => navigate(`/c/${communityName}`)}
                                >
                                    <i className="fas fa-arrow-left"></i>
                                    Back to r/{community.name}
                                </button>
                            </div>
                            <div className="post-page">
                                <div className="post-container">
                                    <div className="post-header">
                                        <Link to={`/user/${authorUsername}`}>
                                            <img 
                                                src={authorProfilePhoto || defaultAvatar} 
                                                alt="Profile" 
                                                className="profile-photo" 
                                            />
                                        </Link>
                                        <div className="post-meta">
                                            <Link to={`/user/${authorUsername}`} className="username-link">
                                                {authorUsername}
                                            </Link>
                                            <span className="post-meta-separator">•</span>
                                            <span className="post-date">{formatDate(post.createdAt)}</span>
                                            {post.lastEdited && (
                                                <>
                                                    <span className="post-meta-separator">•</span>
                                                    <span className="edit-indicator">
                                                        Edited {formatDistanceToNow(
                                                            post.lastEdited instanceof Date 
                                                                ? post.lastEdited 
                                                                : post.lastEdited.toDate()
                                                        )} ago
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {isEditing ? (
                                        <div className="edit-post-form">
                                            <h1 className="post-title">{post.title}</h1>
                                            <RichTextEditor
                                                value={editedContent}
                                                onChange={setEditContent}
                                                placeholder="What are your thoughts?"
                                            />
                                            <div className="edit-actions">
                                                <button 
                                                    className="cancel-button" 
                                                    onClick={() => {
                                                        setIsEditing(false);
                                                        setEditContent(post.content);
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                                <button 
                                                    className="save-button" 
                                                    onClick={handleSaveEdit}
                                                    disabled={!editedContent.trim()}
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <h1 className="post-title">{post.title}</h1>
                                            <div className="post-content">
                                                {post.isDeleted ? (
                                                    <p className="deleted-message">Sorry, this post was deleted by the user.</p>
                                                ) : (
                                                    post.isHtml ? (
                                                        <div dangerouslySetInnerHTML={{ __html: post.content }} />
                                                    ) : (
                                                        post.content
                                                    )
                                                )}
                                            </div>
                                        </>
                                    )}
                                    <div className="post-actions">
                                        <div className="vote-section">
                                            <PostVote 
                                                postId={postId} 
                                                communityName={communityName.toLowerCase()} 
                                            />
                                        </div>
                                        <button className="action-button" onClick={scrollToComments}>
                                            <i className="far fa-comment"></i>
                                            {getCommentsLabel(comments.length)}
                                        </button>
                                        <button className="action-button">
                                            <i className="far fa-share-square"></i>
                                            Share
                                        </button>
                                        <button 
                                            className="action-button"
                                            onClick={() => setShowReportModal(true)}
                                        >
                                            <i className="fas fa-flag"></i>
                                            Report
                                        </button>
                                        {isAuthenticated && user?.uid === post.authorId && !post.isDeleted && (
                                            <>
                                                <button 
                                                    className="action-button"
                                                    onClick={() => setIsEditing(true)}
                                                >
                                                    <i className="far fa-edit"></i>
                                                    Edit
                                                </button>
                                                <button 
                                                    className="action-button"
                                                    onClick={() => setShowDeleteModal(true)}
                                                >
                                                    <i className="far fa-trash-alt"></i>
                                                    Delete
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div ref={commentsRef}>
                                    {post && community && (
                                        <Comments 
                                            postId={postId} 
                                            communityName={communityName.toLowerCase()} 
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="sidebar-column">
                            <AboutCommunity community={community} />
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
            <ReportModal
                isOpen={showReportModal}
                onClose={() => setShowReportModal(false)}
                contentType="post"
                contentData={{
                    postId,
                    communityName,
                    authorId: post.authorId,
                    postUrl: window.location.href,
                    postContent: {
                        title: post.title,
                        content: post.content
                    }
                }}
            />
            <DeleteModal
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onConfirm={handleDeletePost}
            />
        </>
    );
};

export default PostPage; 