import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { collection, query, where, getDocs, collectionGroup } from 'firebase/firestore';
import { db } from '../firebase';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './SearchResults.css';

const SearchResults = () => {
    const [searchParams] = useSearchParams();
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const searchQuery = searchParams.get('q') || '';
    const searchType = searchParams.get('type') || 'communities';
    const navigate = useNavigate();

    useEffect(() => {
        const performSearch = async () => {
            if (!searchQuery) {
                setLoading(false);
                return;
            }

            setLoading(true);
            const searchTerm = searchQuery.toLowerCase();
            let searchResults = [];

            try {
                // Search users if type is 'users'
                if (searchType === 'users') {
                    console.log('Searching users for:', searchTerm);
                    
                    // Create separate queries for each field
                    const usernameQuery = query(
                        collection(db, 'users'),
                        where('userProfile.username', '>=', searchTerm),
                        where('userProfile.username', '<=', searchTerm + '\uf8ff')
                    );

                    const firstNameQuery = query(
                        collection(db, 'users'),
                        where('userProfile.firstName', '>=', searchTerm),
                        where('userProfile.firstName', '<=', searchTerm + '\uf8ff')
                    );

                    const middleNameQuery = query(
                        collection(db, 'users'),
                        where('userProfile.middleName', '>=', searchTerm),
                        where('userProfile.middleName', '<=', searchTerm + '\uf8ff')
                    );

                    const lastNameQuery = query(
                        collection(db, 'users'),
                        where('userProfile.lastName', '>=', searchTerm),
                        where('userProfile.lastName', '<=', searchTerm + '\uf8ff')
                    );

                    // Execute all queries in parallel
                    const [usernameSnapshot, firstNameSnapshot, middleNameSnapshot, lastNameSnapshot] = 
                        await Promise.all([
                            getDocs(usernameQuery),
                            getDocs(firstNameQuery),
                            getDocs(middleNameQuery),
                            getDocs(lastNameQuery)
                        ]);

                    // Use a Map to prevent duplicate users
                    const userMap = new Map();

                    // Helper function to add docs to the map
                    const addDocsToMap = (snapshot) => {
                        snapshot.docs.forEach(doc => {
                            if (!userMap.has(doc.id)) {
                                userMap.set(doc.id, {
                                    type: 'user',
                                    id: doc.id,
                                    ...doc.data().userProfile
                                });
                            }
                        });
                    };

                    // Add results from all queries
                    addDocsToMap(usernameSnapshot);
                    addDocsToMap(firstNameSnapshot);
                    addDocsToMap(middleNameSnapshot);
                    addDocsToMap(lastNameSnapshot);

                    // Convert Map values to array
                    const userData = Array.from(userMap.values());
                    console.log('User search results:', userData);
                    searchResults = [...searchResults, ...userData];
                }

                // Search communities if type is 'communities'
                if (searchType === 'communities') {
                    console.log('Searching communities for:', searchTerm);
                    
                    const nameQuery = query(
                        collection(db, 'communities'),
                        where('nameToLower', '>=', searchTerm),
                        where('nameToLower', '<=', searchTerm + '\uf8ff')
                    );

                    const descriptionQuery = query(
                        collection(db, 'communities'),
                        where('descriptionToLower', '>=', searchTerm),
                        where('descriptionToLower', '<=', searchTerm + '\uf8ff')
                    );

                    const [nameSnapshot, descriptionSnapshot] = await Promise.all([
                        getDocs(nameQuery),
                        getDocs(descriptionQuery)
                    ]);

                    const communityMap = new Map();

                    const addDocsToMap = (snapshot) => {
                        snapshot.docs.forEach(doc => {
                            if (!communityMap.has(doc.id)) {
                                communityMap.set(doc.id, {
                                    type: 'community',
                                    id: doc.id,
                                    ...doc.data()
                                });
                            }
                        });
                    };

                    addDocsToMap(nameSnapshot);
                    addDocsToMap(descriptionSnapshot);

                    const communityData = Array.from(communityMap.values());
                    console.log('Community search results:', communityData);
                    searchResults = [...communityData];
                }

                console.log('Final search results:', searchResults);
                setResults(searchResults);
            } catch (error) {
                console.error('Search error:', error);
            } finally {
                setLoading(false);
            }
        };

        performSearch();
    }, [searchQuery, searchType]);

    const renderResult = (result) => {
        switch (result.type) {
            case 'user':
                return (
                    <Link to={`/user/${result.username}`} className="search-result-card">
                        <div className="user-result">
                            <img 
                                src={result.profileImageUrl || '/default-avatar.png'} 
                                alt="Profile" 
                                className="result-avatar"
                            />
                            <div className="result-info">
                                <h3>@{result.username}</h3>
                                {result.fullName && <p>{result.fullName}</p>}
                            </div>
                        </div>
                    </Link>
                );
            case 'community':
                return (
                    <Link to={`/c/${result.name}`} className="community-link">
                        <div className="community-title">c/{result.name}</div>
                        <div className="community-description">{result.description}</div>
                        <div className="member-count">
                            {result.membersCount || 0} members
                        </div>
                    </Link>
                );
            case 'post':
                return (
                    <Link 
                        to={`/c/${result.communityName}/posts/${result.id}/${result.slug}`} 
                        className="search-result-card"
                    >
                        <div className="post-result">
                            <h3>{result.title}</h3>
                            <p>{result.content?.substring(0, 150)}...</p>
                            <span className="post-meta">
                                Posted in c/{result.communityName}
                            </span>
                        </div>
                    </Link>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <div className="communities-container">
                        <div className="communities-header">
                            <h1>Search Results for "{searchQuery}"</h1>
                        </div>
                        
                        <div className="search-type-tabs">
                            <button 
                                className={searchType === 'communities' ? 'active' : ''} 
                                onClick={() => navigate(`/search?q=${searchQuery}&type=communities`)}
                            >
                                Communities
                            </button>
                            <button 
                                className={searchType === 'users' ? 'active' : ''} 
                                onClick={() => navigate(`/search?q=${searchQuery}&type=users`)}
                            >
                                Users
                            </button>
                            <button 
                                className={searchType === 'posts' ? 'active' : ''} 
                                onClick={() => navigate(`/search?q=${searchQuery}&type=posts`)}
                            >
                                Posts
                            </button>
                        </div>

                        {loading ? (
                            <div className="loading-container">
                                <div className="loading-spinner"></div>
                                <p>Searching...</p>
                            </div>
                        ) : results.length > 0 ? (
                            <div className="communities-list">
                                {results.map((result, index) => (
                                    <div key={index} className="community-card">
                                        {renderResult(result)}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="no-results">
                                <p>No results found for "{searchQuery}"</p>
                            </div>
                        )}
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default SearchResults;
