import React from 'react';
import './AboutCommunity.css';
import { Link } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';

const AboutCommunity = ({ community }) => {
    return (
        <div className="about-community">
            <h2>About Community</h2>
            <div className="community-info">
                <p>{community.description}</p>
                <div className="members-count">
                    <span>{community.membersCount || 1}</span>
                    <span>Members</span>
                </div>
                <p className="created-date">
                    Created {formatDistanceToNow(community.createdAt?.toDate())} ago by{' '}
                    <Link to={`/user/${community.creatorUsername}`} className="username-link">
                        {community.creatorUsername}
                    </Link>
                </p>
            </div>
        </div>
    );
};

export default AboutCommunity;
