import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import './UserMenu.css';
import { DEFAULT_PROFILE_PHOTO } from '../../utils/constants';

const UserMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { user } = useAuth();
    const navigate = useNavigate();
    const menuRef = useRef();
    const [username, setUsername] = useState('');
    const [profilePhoto, setProfilePhoto] = useState('');

    useEffect(() => {
        if (user) {
            const unsubscribe = onSnapshot(doc(db, 'users', user.uid), (docSnapshot) => {
                if (docSnapshot.exists() && docSnapshot.data().userProfile) {
                    const userData = docSnapshot.data().userProfile;
                    setUsername(userData.username);
                    setProfilePhoto(userData.profileImageUrl);
                    console.log('User profile updated:', userData);
                }
            }, (error) => {
                console.error("Error listening to profile updates:", error);
            });

            return () => unsubscribe();
        }
    }, [user]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            setIsOpen(false);
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <div className="user-menu-container" ref={menuRef}>
            <button 
                className="avatar-button"
                onClick={() => setIsOpen(!isOpen)}
            >
                <img 
                    src={profilePhoto || DEFAULT_PROFILE_PHOTO} 
                    alt="User avatar" 
                    className="nav-avatar"
                />
            </button>

            {isOpen && (
                <div className="dropdown-menu">
                    <Link 
                        to="/settings/profile" 
                        onClick={() => setIsOpen(false)}
                        className="menu-item"
                    >
                        Edit Profile
                    </Link>
                    <Link 
                        to="/followers"
                        className="menu-item"
                        onClick={() => setIsOpen(false)}
                    >
                        Followers
                    </Link>
                    <Link 
                        to="/following"
                        className="menu-item"
                        onClick={() => setIsOpen(false)}
                    >
                        Following
                    </Link>
                    <Link 
                        to="/settings/account" 
                        className="menu-item"
                        onClick={() => setIsOpen(false)}
                    >
                        Account Settings
                    </Link>
                    <button 
                        className="menu-item"
                        onClick={handleSignOut}
                    >
                        Sign Out
                    </button>
                </div>
            )}
        </div>
    );
};

export default UserMenu;
