import React, { useState } from 'react';
import { useAuth } from '../AuthContext';
import { db } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import './SetupUsernamePassword.css';

const SetupUsernamePassword = () => {
    console.log('SetupUsernamePassword component is rendering');
    const { user } = useAuth();
    const navigate = useNavigate();
    const [username, setUsername] = useState(`user_${Math.random().toString(36).substring(2, 8)}`);
    const [usernameMessage, setUsernameMessage] = useState('');
    const [isUsernameValid, setIsUsernameValid] = useState(false);

    if (!user) {
        console.error('User is not authenticated');
        return null;
    }

    const validateUsername = async () => {
        console.log('Validating username:', username);
        if (username.length < 3) {
            setUsernameMessage('Usernames must be at least 3 characters long.');
            setIsUsernameValid(false);
        } else {
            const isUnique = true; // Replace with actual uniqueness check
            setIsUsernameValid(isUnique);
            setUsernameMessage(isUnique ? 'Nice! Username available' : 'Username is taken.');
        }
    };

    const handleSubmit = async () => {
        console.log('Submitting username:', username);
        if (isUsernameValid) {
            try {
                await updateDoc(doc(db, 'users', user.uid), {
                    userProfile: { 
                        username,
                        isDefaultUsername: false
                    }
                });
                navigate('/dashboard', { state: { message: `Your username ${username} was successfully created!` } });
            } catch (error) {
                console.error('Error updating profile:', error);
            }
        }
    };

    return (
        <>
            <Header />
            <div className="setup-container">
                <div className="setup-box">
                    <h1>Create your username</h1>
                    <p>Your username will be publicly associated with your account. Please pick carefully as it cannot be changed in the future.</p>
                    <div className="input-group">
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            onBlur={validateUsername}
                            placeholder="Username"
                        />
                        {isUsernameValid && <span className="checkmark">✔️</span>}
                    </div>
                    <p className={isUsernameValid ? 'success' : 'error'}>{usernameMessage}</p>
                    <button 
                        onClick={handleSubmit} 
                        disabled={!isUsernameValid}
                        className={isUsernameValid ? 'active' : ''}
                    >
                        Continue
                    </button>
                </div>
            </div>
        </>
    );
};

export default SetupUsernamePassword;
