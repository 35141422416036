import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './TermsOfService.css';

const TermsOfService = () => {
    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <div className="terms-container">
                        <h1>Terms of Service</h1>
                        
                        <section>
                            <h2>1. Acceptance of Terms</h2>
                            <p>By accessing and using FencerStats, you accept and agree to be bound by these Terms of Service.</p>
                        </section>

                        <section>
                            <h2>2. User Accounts</h2>
                            <p>Users must provide accurate information when creating accounts. Users are responsible for maintaining the security of their accounts and all activities that occur under their accounts.</p>
                        </section>

                        <section>
                            <h2>3. Content and Conduct</h2>
                            <p>Users are responsible for all content they post, including profiles, messages, comments, and other materials. Content must not violate any applicable laws or infringe on others' rights.</p>
                        </section>

                        <section>
                            <h2>4. Data and Statistics</h2>
                            <p>While we strive for accuracy, we cannot guarantee the accuracy of all fencing statistics and results. Users should verify critical information independently.</p>
                        </section>

                        <section>
                            <h2>5. Advertising and Commercial Usage</h2>
                            <p>We reserve the right to display advertisements. Commercial users must obtain proper authorization for advertising services.</p>
                        </section>

                        <section>
                            <h2>6. Intellectual Property</h2>
                            <p>Users retain rights to their content but grant FencerStats a license to use, modify, and display the content on our platform.</p>
                        </section>

                        <section>
                            <h2>7. Modifications to Service</h2>
                            <p>We reserve the right to modify or discontinue any aspect of the service at any time.</p>
                        </section>

                        <section>
                            <h2>8. Limitation of Liability</h2>
                            <p>FencerStats is not liable for any indirect, incidental, or consequential damages arising from use of the service.</p>
                        </section>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default TermsOfService; 