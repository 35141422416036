import React from 'react';
import { useParams } from 'react-router-dom';
import CreatePost from '../components/CreatePost';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './CreatePostPage.css';

const CreatePostPage = () => {
    const { communityName } = useParams();

    return (
        <>
            <Header />
            <main>
                <div className="create-post-page">
                    <h1>Create a Post in r/{communityName}</h1>
                    <CreatePost communityName={communityName.toLowerCase()} />
                </div>
            </main>
            <Footer />
        </>
    );
};

export default CreatePostPage;
