import { db } from '../firebase';
import { 
    collection, 
    addDoc, 
    deleteDoc, 
    query, 
    where, 
    getDocs,
    serverTimestamp 
} from 'firebase/firestore';

// Follow a user
export const followUser = async (followerId, followingId) => {
    try {
        await addDoc(collection(db, 'follows'), {
            followerId,
            followingId,
            timestamp: serverTimestamp()
        });
        return true;
    } catch (error) {
        console.error('Error following user:', error);
        throw error;
    }
};

// Unfollow a user
export const unfollowUser = async (followerId, followingId) => {
    try {
        const q = query(
            collection(db, 'follows'),
            where('followerId', '==', followerId),
            where('followingId', '==', followingId)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
            await deleteDoc(doc.ref);
        });
        return true;
    } catch (error) {
        console.error('Error unfollowing user:', error);
        throw error;
    }
};

// Check if user is following another user
export const isFollowing = async (followerId, followingId) => {
    try {
        const q = query(
            collection(db, 'follows'),
            where('followerId', '==', followerId),
            where('followingId', '==', followingId)
        );
        const querySnapshot = await getDocs(q);
        return !querySnapshot.empty;
    } catch (error) {
        console.error('Error checking follow status:', error);
        throw error;
    }
};
