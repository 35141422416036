import React, { useState, useEffect } from 'react';
import { handleVote, getVoteCount, getUserVote } from '../votes';
import { useAuth } from '../AuthContext';

const PostVote = ({ postId, communityName }) => {
  const [voteCount, setVoteCount] = useState(0);
  const [userVote, setUserVote] = useState(0);
  const { user } = useAuth();

  useEffect(() => {
    const loadVotes = async () => {
      const voteId = `${communityName.toLowerCase()}_${postId}`;
      const count = await getVoteCount(voteId);
      setVoteCount(count);
      
      if (user) {
        const userVoteValue = await getUserVote(voteId, user.uid);
        setUserVote(userVoteValue);
      }
    };
    
    loadVotes();
  }, [postId, communityName, user]);

  const onVote = async (newVoteType) => {
    if (!user) {
      alert('Please sign in to vote');
      return;
    }

    const voteId = `${communityName.toLowerCase()}_${postId}`;
    const voteValue = userVote === newVoteType ? 0 : newVoteType;
    const success = await handleVote(voteId, user.uid, voteValue);
    
    if (success) {
      const voteDiff = voteValue - userVote;
      setVoteCount(prev => prev + voteDiff);
      setUserVote(voteValue);
    }
  };

  return (
    <div className="vote-container">
      <button 
        onClick={() => onVote(1)}
        className={`vote-button upvote ${userVote === 1 ? 'active-upvote' : ''}`}
      >
        ▲
      </button>
      <span className="vote-count">{voteCount}</span>
      <button 
        onClick={() => onVote(-1)}
        className={`vote-button downvote ${userVote === -1 ? 'active-downvote' : ''}`}
      >
        ▼
      </button>
    </div>
  );
};

export default PostVote;
