import React, { useState } from 'react';
import './MessageModal.css';

const MessageModal = ({ isOpen, onClose, recipientUsername, onSendMessage }) => {
    const [message, setMessage] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSending(true);
        try {
            await onSendMessage(message);
            setShowConfirmation(true);
            setMessage(''); // Clear the message
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setIsSending(false);
        }
    };

    const handleClose = () => {
        setShowConfirmation(false); // Reset confirmation state
        onClose(); // Close the modal
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                {showConfirmation ? (
                    <div className="confirmation-message">
                        <h2>Message Sent!</h2>
                        <p>Your message has been sent successfully.</p>
                        <div className="modal-buttons">
                            <button 
                                type="button" 
                                onClick={handleClose}
                                className="close-button"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                ) : (
                    <>
                        <h2>Send Message to @{recipientUsername}</h2>
                        <form onSubmit={handleSubmit}>
                            <textarea
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Write your message..."
                                className="message-input"
                                disabled={isSending}
                            />
                            <div className="modal-buttons">
                                <button 
                                    type="submit" 
                                    disabled={isSending || !message.trim()}
                                >
                                    {isSending ? 'Sending...' : 'Send'}
                                </button>
                                <button 
                                    type="button" 
                                    onClick={handleClose}
                                    disabled={isSending}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
};

export default MessageModal;
