import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './About.css';

const About = () => {
    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <div className="about-container">
                        <h1>About FencerStats</h1>
                        <p>FencerStats was built to help the fencing community track fencers, statistics, news, and results. If you'd like to suggest additional features, let us know!</p>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default About; 