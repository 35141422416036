import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { db } from '../firebase';
import { collection, addDoc, getDocs, query, where, orderBy, limit } from 'firebase/firestore';
import { useAuth } from '../AuthContext';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './Community.css';
import PostVote from '../components/PostVote';

const Community = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [communityName, setCommunityName] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');
    const [isNameTaken, setIsNameTaken] = useState(false);
    const [communities, setCommunities] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user, isAuthenticated } = useAuth();
    const navigate = useNavigate();

    // Fetch popular communities
    useEffect(() => {
        const fetchPopularCommunities = async () => {
            try {
                const q = query(
                    collection(db, 'communities'),
                    orderBy('members', 'desc'),  // Order by array size
                    limit(10)
                );
                const querySnapshot = await getDocs(q);
                
                const communitiesList = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    membersCount: doc.data().members?.length || 0  // Calculate member count
                }));
                
                setCommunities(communitiesList);
            } catch (error) {
                setError('Error loading communities: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPopularCommunities();
    }, []);

    // Function to validate community name format
    const validateCommunityName = (name) => {
        // Only allow letters, numbers, and underscores
        return /^[a-zA-Z0-9_]*$/.test(name);
    };

    // Function to check if community name is available
    const checkCommunityName = async (name) => {
        if (!name) return;

        try {
            const q = query(
                collection(db, 'communities'),
                where('nameToLower', '==', name.toLowerCase())
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                setError(`"${name}" is already taken`);
                setIsNameTaken(true);
            } else {
                setError('');
                setIsNameTaken(false);
            }
        } catch (err) {
            console.error('Error checking community name:', err);
        }
    };

    // Handle community name input
    const handleCommunityNameChange = (e) => {
        const value = e.target.value.trim();
        
        if (!validateCommunityName(value)) {
            setError('Community names must only contain letters, numbers, or underscores');
            return;
        }

        setCommunityName(value);
        setError('');
    };

    // Handle community name blur (when user clicks away)
    const handleCommunityNameBlur = () => {
        if (communityName) {
            checkCommunityName(communityName);
        }
    };

    const handleCreateCommunity = async (e) => {
        e.preventDefault();
        
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        if (isNameTaken) {
            return;
        }

        try {
            await addDoc(collection(db, 'communities'), {
                name: communityName,
                nameToLower: communityName.toLowerCase(),
                description: description,
                createdBy: user.uid,
                createdAt: new Date(),
                members: [user.uid]
            });

            navigate(`/c/${communityName}`);
        } catch (error) {
            setError('Error creating community: ' + error.message);
        }
    };

    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <div className="communities-header">
                        <h1>Communities</h1>
                        <button 
                            className="create-community-button"
                            onClick={() => setIsModalOpen(true)}
                        >
                            Create a community
                        </button>
                    </div>

                    {/* Popular Communities List */}
                    {loading ? (
                        <div className="loading-container">
                            <div className="loading-spinner"></div>
                            <div>Loading communities...</div>
                        </div>
                    ) : (
                        <div className="communities-list">
                            {communities.map(community => (
                                <div key={community.id} className="community-card">
                                    <Link to={`/c/${community.name}`} className="community-link">
                                        <h2>{community.name}</h2>
                                        <p>{community.description}</p>
                                        <p className="member-count">
                                            {community.membersCount} {community.membersCount === 1 ? 'member' : 'members'}
                                        </p>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Create Community Modal */}
                    {isModalOpen && (
                        <div className="modal-overlay">
                            <div className="modal-content">
                                <h2>Create your community</h2>
                                <p>Name your community and give a description so others can find it.</p>
                                
                                <div className="community-creation-container">
                                    <div className="community-form">
                                        <form onSubmit={handleCreateCommunity}>
                                            <div className="form-group">
                                                <label>Community name</label>
                                                <div className="input-prefix-container">
                                                    <span className="input-prefix">c/</span>
                                                    <input
                                                        type="text"
                                                        value={communityName}
                                                        onChange={handleCommunityNameChange}
                                                        onBlur={handleCommunityNameBlur}
                                                        required
                                                        maxLength={21}
                                                        className={error ? 'error' : ''}
                                                    />
                                                </div>
                                                {error && <p className="error-message">{error}</p>}
                                            </div>

                                            <div className="form-group">
                                                <label>Description</label>
                                                <textarea
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    required
                                                    rows={4}
                                                />
                                            </div>

                                            <div className="modal-buttons">
                                                <button 
                                                    type="button" 
                                                    onClick={() => setIsModalOpen(false)}
                                                    className="cancel-button"
                                                >
                                                    Cancel
                                                </button>
                                                <button 
                                                    type="submit"
                                                    className="create-button"
                                                    disabled={!communityName || isNameTaken || !!error}
                                                >
                                                    Create Community
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                    <div className="community-preview">
                                        <div className="preview-content">
                                            <div className="preview-title">c/{communityName || 'communityname'}</div>
                                            <div className="preview-stats">1 member • 1 online</div>
                                            <div className="preview-description">
                                                {description || 'Your community description'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </main>
            <Footer />
        </>
    );
};

export default Community;
