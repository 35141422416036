import React, { useState } from 'react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../AuthContext';
import RichTextEditor from '../RichTextEditor';

const CommentForm = ({ postId, communityName, parentId = null, onReplyComplete = null }) => {
    const [content, setContent] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { user } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!content.trim()) return;

        setIsSubmitting(true);
        try {
            const commentsRef = collection(db, 'communities', communityName.toLowerCase(), 'posts', postId, 'comments');
            await addDoc(commentsRef, {
                content: content.trim(),
                authorId: user.uid,
                createdAt: serverTimestamp(),
                parentId: parentId,
                postId: postId,
                isDeleted: false,
                isHtml: true
            });

            setContent('');
            if (onReplyComplete) onReplyComplete();
        } catch (error) {
            console.error('Error adding comment:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="comment-form">
            <RichTextEditor
                value={content}
                onChange={setContent}
                placeholder="What are your thoughts?"
            />
            <button 
                type="submit" 
                disabled={isSubmitting || !content.trim()}
                className="submit-button"
            >
                {isSubmitting ? 'Posting...' : 'Post'}
            </button>
        </form>
    );
};

export default CommentForm;
