import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { getMessages } from '../services/messageService';
import MessageThread from '../components/MessageThread';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './Inbox.css';
import { Link } from 'react-router-dom';

const defaultAvatar = "https://firebasestorage.googleapis.com/v0/b/fencerstats.appspot.com/o/defaultprofile%2Fdefault_profile.png?alt=media";

const Inbox = () => {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null);
    const { user } = useAuth();

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const fetchedMessages = await getMessages(user.uid);
                setMessages(fetchedMessages);
            } catch (error) {
                console.error('Error fetching messages:', error);
            } finally {
                setLoading(false);
            }
        };

        if (user) {
            fetchMessages();
        }
    }, [user]);

    const formatDate = (timestamp) => {
        if (!timestamp) return 'No date';
        try {
            if (timestamp.toDate) {
                return timestamp.toDate().toLocaleDateString();
            }
            return new Date(timestamp).toLocaleDateString();
        } catch (error) {
            return 'Invalid date';
        }
    };

    // Group messages by sender/recipient
    const getUniqueConversations = () => {
        const conversations = new Map();
        
        messages.forEach(message => {
            const otherUserId = message.senderId === user.uid ? message.recipientId : message.senderId;
            const otherUserName = message.senderId === user.uid ? message.recipientName : message.senderName;
            const otherUserImage = message.senderId === user.uid ? message.recipientImage : message.senderImage;
            
            if (!conversations.has(otherUserId) || message.timestamp > conversations.get(otherUserId).timestamp) {
                conversations.set(otherUserId, {
                    userId: otherUserId,
                    name: otherUserName,
                    image: otherUserImage,
                    lastMessage: message.message,
                    timestamp: message.timestamp
                });
            }
        });

        return Array.from(conversations.values());
    };

    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <div className="inbox-container">
                        <div className="inbox-sidebar">
                            <h2>Conversations</h2>
                            {loading ? (
                                <div className="loading-container">
                                    <div className="loading-spinner"></div>
                                    <div>Loading conversations...</div>
                                </div>
                            ) : getUniqueConversations().length === 0 ? (
                                <div className="no-conversations">
                                    <p>You do not have any conversations.</p>
                                    <p>Start following people and send them a message to get conversations started!</p>
                                </div>
                            ) : (
                                <div className="conversation-list">
                                    {getUniqueConversations().map((conversation) => (
                                        <div 
                                            key={conversation.userId}
                                            className={`conversation-item ${selectedUser === conversation.userId ? 'selected' : ''}`}
                                            onClick={() => setSelectedUser(conversation.userId)}
                                        >
                                            <Link 
                                                to={`/user/${conversation.name}`} 
                                                className="conversation-avatar"
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <img 
                                                    src={conversation.image || defaultAvatar} 
                                                    alt={conversation.name}
                                                    className="avatar-image"
                                                />
                                            </Link>
                                            <div className="conversation-content">
                                                <div className="conversation-name">{conversation.name}</div>
                                                <div className="conversation-preview">{conversation.lastMessage}</div>
                                                <div className="conversation-date">
                                                    {formatDate(conversation.timestamp)}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="inbox-messages">
                            {selectedUser ? (
                                <MessageThread 
                                    otherUserId={selectedUser} 
                                    messages={messages.filter(m => 
                                        m.senderId === selectedUser || m.recipientId === selectedUser
                                    )}
                                />
                            ) : (
                                <div className="no-message-selected">
                                    Select a conversation to view messages
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default Inbox;
