import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithGoogle, sendEmailLink } from '../googleAuth';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './SignUpPage.css';

const SignUpPage = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [isEmailSent, setIsEmailSent] = useState(false);
    const navigate = useNavigate();

    const handleEmailSignUp = async (e) => {
        e.preventDefault();
        try {
            await sendEmailLink(email);
            setIsEmailSent(true);
            setError('');
        } catch (error) {
            setError(error.message);
        }
    };

    const handleGoogleSignUp = async () => {
        try {
            await signInWithGoogle();
            navigate('/dashboard');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <>
            <Header hideAuthButton={true} />
            <div className="signup-page">
                <div className="signup-container">
                    {isEmailSent ? (
                        <div className="confirmation-message">
                            <h2>✉️ Check your email!</h2>
                            <p>We've sent a sign-up link to:</p>
                            <p className="email-address">{email}</p>
                            <p className="instructions">
                                Click the link in the email to complete your account setup.
                                <br />
                                Don't see it? Check your spam folder.
                            </p>
                            <button 
                                className="signup-back-button"
                                onClick={() => {
                                    setIsEmailSent(false);
                                    setEmail('');
                                }}
                            >
                                Back to Sign Up
                            </button>
                        </div>
                    ) : (
                        <>
                            <h2>Create Account</h2>
                            {error && <p className="error">{error}</p>}
                            
                            <form onSubmit={handleEmailSignUp}>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <button type="submit" className="email-button">
                                    Sign Up with Email
                                </button>
                            </form>

                            <div className="divider">
                                <span>OR</span>
                            </div>

                            <button 
                                className="google-button"
                                onClick={handleGoogleSignUp}
                            >
                                <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google" />
                                Sign Up with Google
                            </button>
                        </>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default SignUpPage;
