import React, { useState } from 'react';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './ContactPage.css';

const ContactPage = () => {
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            const functions = getFunctions();
            // Connect to local emulator when in development
            if (process.env.NODE_ENV === 'development') {
                connectFunctionsEmulator(functions, 'localhost', 5001);
            }
            
            const sendContactEmail = httpsCallable(functions, 'sendContactEmail');
            
            const result = await sendContactEmail({
                email,
                subject,
                message
            });
            
            console.log('Function result:', result); // Debug log
            setIsEmailSent(true);
        } catch (error) {
            console.error('Error sending message:', error);
            setError('Failed to send message. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <div className="contact-page">
                        <div className="contact-container">
                            {isEmailSent ? (
                                <div className="confirmation-message">
                                    <h2>✉️ Message Sent!</h2>
                                    <p>Thank you for contacting us. We'll get back to you soon.</p>
                                    <button 
                                        className="back-button"
                                        onClick={() => {
                                            setIsEmailSent(false);
                                            setEmail('');
                                            setSubject('');
                                            setMessage('');
                                        }}
                                    >
                                        Send Another Message
                                    </button>
                                </div>
                            ) : (
                                <>
                                    <h2>Contact Us</h2>
                                    {error && <p className="error">{error}</p>}
                                    
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                placeholder="Your email address"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Subject"
                                                value={subject}
                                                onChange={(e) => setSubject(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <textarea
                                                placeholder="Your message"
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                                required
                                                rows={6}
                                            />
                                        </div>
                                        <button type="submit" className="submit-button">
                                            Send Message
                                        </button>
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default ContactPage;
