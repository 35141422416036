import React from 'react';
import '../DeleteModal.css'; // Reuse the existing DeleteModal styles

const DeleteCommentModal = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Delete Comment</h2>
                <p>Are you sure you want to delete this comment? This action cannot be undone.</p>
                <div className="modal-buttons">
                    <button 
                        className="delete-button"
                        onClick={onConfirm}
                    >
                        Delete
                    </button>
                    <button 
                        className="cancel-button"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteCommentModal;
