import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { db } from '../firebase';
import { collection, query, where, orderBy, onSnapshot, or, and, doc, getDoc } from 'firebase/firestore';
import './MessageThread.css';
import { Link } from 'react-router-dom';
import { sendMessage } from '../services/messageService';

const defaultAvatar = "https://firebasestorage.googleapis.com/v0/b/fencerstats.appspot.com/o/defaultprofile%2Fdefault_profile.png?alt=media";

const MessageThread = ({ otherUserId, messages: initialMessages }) => {
    const [newMessage, setNewMessage] = useState('');
    const [messages, setMessages] = useState(initialMessages || []);
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [otherUserProfile, setOtherUserProfile] = useState(null);
    const [currentUserProfile, setCurrentUserProfile] = useState(null);

    // Fetch user profiles
    useEffect(() => {
        const fetchProfiles = async () => {
            try {
                // Fetch other user's profile
                const otherUserDoc = await getDoc(doc(db, 'users', otherUserId));
                if (otherUserDoc.exists()) {
                    setOtherUserProfile(otherUserDoc.data());
                }

                // Fetch current user's profile
                const currentUserDoc = await getDoc(doc(db, 'users', user.uid));
                if (currentUserDoc.exists()) {
                    setCurrentUserProfile(currentUserDoc.data());
                }
            } catch (error) {
                console.error('Error fetching profiles:', error);
            }
        };

        if (user?.uid && otherUserId) {
            fetchProfiles();
        }
    }, [user, otherUserId]);

    // Existing messages listener
    useEffect(() => {
        if (!user?.uid || !otherUserId) {
            setLoading(false);
            return;
        }

        try {
            const messagesRef = collection(db, 'messages');
            const q = query(
                messagesRef,
                or(
                    and(
                        where('senderId', '==', user.uid),
                        where('recipientId', '==', otherUserId)
                    ),
                    and(
                        where('senderId', '==', otherUserId),
                        where('recipientId', '==', user.uid)
                    )
                ),
                orderBy('timestamp', 'asc')
            );

            const unsubscribe = onSnapshot(q, 
                (snapshot) => {
                    const newMessages = [];
                    snapshot.forEach((doc) => {
                        const messageData = doc.data();
                        console.log('Message Data:', messageData);
                        newMessages.push({
                            id: doc.id,
                            ...messageData
                        });
                    });
                    console.log('Current user:', user);
                    console.log('All messages:', newMessages);
                    setMessages(newMessages);
                    setLoading(false);
                },
                (error) => {
                    console.error("Error in messages listener:", error);
                    setLoading(false);
                }
            );

            return () => unsubscribe();
        } catch (error) {
            console.error("Error setting up messages listener:", error);
            setLoading(false);
        }
    }, [user, otherUserId]);

    console.log('Rendering messages:', messages);
    console.log('Current user data:', user);

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim()) return;

        try {
            await sendMessage(
                user.uid,
                otherUserId,
                newMessage.trim()
            );
            
            // Add the new message to the messages state
            const newMessageObj = {
                senderId: user.uid,
                recipientId: otherUserId,
                message: newMessage.trim(),
                timestamp: {
                    // Create a timestamp that matches Firestore's format
                    toDate: () => new Date(),
                    seconds: Math.floor(Date.now() / 1000),
                    nanoseconds: 0
                },
                read: false
            };
            setMessages(prevMessages => [...prevMessages, newMessageObj]);
            setNewMessage(''); // Clear input after sending
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    // Helper function to format the timestamp
    const formatTimestamp = (timestamp) => {
        if (!timestamp) return '';
        
        // Handle both Firestore Timestamp and our local timestamp
        const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
        return date.toLocaleTimeString([], { 
            hour: '2-digit', 
            minute: '2-digit' 
        });
    };

    return (
        <div className="message-thread">
            {messages.length === 0 ? (
                <div className="no-messages">
                    No messages yet. Start a conversation!
                </div>
            ) : (
                <div className="messages-list">
                    {messages.map(message => {
                        console.log('Rendering message:', {
                            messageId: message.id,
                            senderId: message.senderId,
                            senderImage: message.senderImage,
                            userImage: user.profileImageUrl
                        });
                        
                        return (
                            <div 
                                key={message.id} 
                                className={`message ${message.senderId === user.uid ? 'sent' : 'received'}`}
                            >
                                <div className="message-wrapper">
                                    <Link 
                                        to={`/user/${message.senderId === user.uid ? 
                                            currentUserProfile?.userProfile?.username : 
                                            otherUserProfile?.userProfile?.username}`}
                                        className="message-avatar"
                                    >
                                        <img 
                                            src={message.senderId === user.uid ? 
                                                (currentUserProfile?.userProfile?.profileImageUrl || defaultAvatar) : 
                                                (otherUserProfile?.userProfile?.profileImageUrl || defaultAvatar)
                                            } 
                                            alt={message.senderId === user.uid ? 
                                                currentUserProfile?.userProfile?.username : 
                                                otherUserProfile?.userProfile?.username}
                                            className="avatar-image"
                                            onError={(e) => {
                                                console.log('Image failed to load:', e.target.src);
                                                e.target.src = defaultAvatar;
                                            }}
                                        />
                                    </Link>
                                    <div className="message-bubble">
                                        <Link 
                                            to={`/user/${message.senderId === user.uid ? 
                                                currentUserProfile?.userProfile?.username : 
                                                otherUserProfile?.userProfile?.username}`}
                                            className="message-username"
                                        >
                                            {message.senderId === user.uid ? 
                                                currentUserProfile?.userProfile?.username : 
                                                otherUserProfile?.userProfile?.username}
                                        </Link>
                                        <div className="message-content">
                                            {message.message}
                                        </div>
                                        <div className="message-timestamp">
                                            {formatTimestamp(message.timestamp)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}

            <form onSubmit={handleSendMessage} className="message-input-form">
                <textarea
                    className="message-input"
                    placeholder="Write a message..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    rows={3}
                />
                <button 
                    type="submit" 
                    className="send-button"
                    disabled={!newMessage.trim()}
                >
                    Send
                </button>
            </form>
        </div>
    );
};

export default MessageThread;
