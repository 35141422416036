import { db } from './firebase';
import { 
  doc, 
  setDoc, 
  deleteDoc, 
  collection, 
  query, 
  where, 
  getDocs,
  getDoc,
  increment 
} from 'firebase/firestore';

// Create or update a vote
export const handleVote = async (itemId, userId, voteType) => {
  try {
    const voteRef = doc(db, 'votes', `${itemId}_${userId}`);
    const totalVotesRef = doc(db, 'totalVotes', itemId);
    
    // Get current vote if exists
    const currentVoteDoc = await getDoc(voteRef);
    const currentVote = currentVoteDoc.exists() ? currentVoteDoc.data().voteType : 0;
    
    // Calculate vote difference
    const voteDiff = voteType - currentVote;
    
    if (voteType === 0) {
      // Remove vote if user clicks same button again
      await deleteDoc(voteRef);
    } else {
      // Add or update vote
      await setDoc(voteRef, {
        itemId,
        userId,
        voteType,
        createdAt: new Date()
      });
    }
    
    // Update total votes
    await setDoc(totalVotesRef, {
      count: increment(voteDiff)
    }, { merge: true });
    
    return true;
  } catch (error) {
    console.error('Error handling vote:', error);
    return false;
  }
};

// Get total vote count
export const getVoteCount = async (itemId) => {
  try {
    const totalVotesRef = doc(db, 'totalVotes', itemId);
    const totalVotesDoc = await getDoc(totalVotesRef);
    
    return totalVotesDoc.exists() ? totalVotesDoc.data().count : 0;
  } catch (error) {
    console.error('Error getting vote count:', error);
    return 0;
  }
};

// Get user's vote
export const getUserVote = async (itemId, userId) => {
  try {
    const voteRef = doc(db, 'votes', `${itemId}_${userId}`);
    const voteDoc = await getDoc(voteRef);
    
    return voteDoc.exists() ? voteDoc.data().voteType : 0;
  } catch (error) {
    console.error('Error getting user vote:', error);
    return 0;
  }
};
