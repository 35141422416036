// src/HomePage.js
import React from 'react';
import { Navigate, useSearchParams, Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './HomePage.css';

const HomePage = () => {
    const { isAuthenticated } = useAuth();
    const [searchParams] = useSearchParams();
    const mode = searchParams.get('mode');

    // Redirect to dashboard if authenticated and coming from email verification
    if (isAuthenticated && mode === 'signIn') {
        return <Navigate to="/dashboard" replace />;
    }

    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <div className="fencerstats-homepage">
                        <h1>FencerStats is a community-driven platform for fencers, coaches, and fencing enthusiasts.</h1>
                        <Link to="/signup" className="signup-button-homepage">
                            Sign Up
                        </Link>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}

export default HomePage;