import React, { useState } from 'react';
import { useAuth } from '../AuthContext';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import './ReportModal.css';

const ReportModal = ({ isOpen, onClose, contentType, contentData }) => {
    const [reason, setReason] = useState('');
    const [otherReason, setOtherReason] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { user } = useAuth();

    const reasons = [
        { value: 'spam', label: 'Spam' },
        { value: 'harassment', label: 'Harassment' },
        { value: 'inappropriate', label: 'Inappropriate content' },
        { value: 'other', label: 'Other' }
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const sendReport = httpsCallable(functions, 'sendReport');
            await sendReport({
                contentType,
                contentData,
                reason: reason === 'other' ? otherReason : reason,
                reporterId: user.uid
            });

            onClose();
        } catch (error) {
            console.error('Error submitting report:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Report Content</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Reason for reporting:</label>
                        <select 
                            value={reason} 
                            onChange={(e) => setReason(e.target.value)}
                            required
                        >
                            <option value="">Select a reason</option>
                            {reasons.map(r => (
                                <option key={r.value} value={r.value}>
                                    {r.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    {reason === 'other' && (
                        <div className="form-group">
                            <label>Please explain:</label>
                            <textarea
                                value={otherReason}
                                onChange={(e) => setOtherReason(e.target.value)}
                                required
                                rows={4}
                            />
                        </div>
                    )}

                    <div className="modal-buttons">
                        <button 
                            type="submit" 
                            disabled={isSubmitting}
                            className="submit-button"
                        >
                            {isSubmitting ? 'Submitting...' : 'Submit Report'}
                        </button>
                        <button 
                            type="button" 
                            onClick={onClose}
                            className="cancel-button"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ReportModal;
