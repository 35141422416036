import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../AuthContext';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './FollowingPage.css';
import { 
    followUser, 
    unfollowUser, 
    isFollowing as checkIsFollowing 
} from '../services/followService';

const defaultAvatar = "https://firebasestorage.googleapis.com/v0/b/fencerstats.appspot.com/o/defaultprofile%2Fdefault_profile.png?alt=media";

const FollowingPage = () => {
    const { username } = useParams();
    const { user, isAuthenticated } = useAuth();
    const [following, setFollowing] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [pageUser, setPageUser] = useState(null);
    const [followStatus, setFollowStatus] = useState({});
    const [hoverStatus, setHoverStatus] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchFollowing = async () => {
            try {
                let targetUserId;
                let targetUsername = username;

                if (username) {
                    console.log('Fetching for username:', username);
                    const usersRef = collection(db, 'users');
                    const q = query(usersRef, where('userProfile.username', '==', username));
                    const userSnapshot = await getDocs(q);
                    
                    if (!userSnapshot.empty) {
                        targetUserId = userSnapshot.docs[0].id;
                        console.log('Found targetUserId:', targetUserId);
                        setPageUser(userSnapshot.docs[0].data().userProfile);
                    } else {
                        console.log('No user found for username:', username);
                        setError('User not found');
                        setLoading(false);
                        return;
                    }
                } else if (isAuthenticated) {
                    console.log('Using authenticated user:', user.uid);
                    targetUserId = user.uid;
                    targetUsername = user.displayName;
                }

                const followsRef = collection(db, 'follows');
                const q = query(followsRef, where('followerId', '==', targetUserId));
                const querySnapshot = await getDocs(q);
                
                console.log('Found following count:', querySnapshot.docs.length);

                const followingPromises = querySnapshot.docs.map(async (docSnapshot) => {
                    try {
                        const followingUserDoc = await getDoc(doc(db, 'users', docSnapshot.data().followingId));
                        if (!followingUserDoc.exists()) {
                            console.log('No user document found for ID:', docSnapshot.data().followingId);
                            return null;
                        }
                        return {
                            id: docSnapshot.data().followingId,
                            ...followingUserDoc.data().userProfile
                        };
                    } catch (err) {
                        console.error('Error fetching user document:', err);
                        return null;
                    }
                });

                const followingDetails = (await Promise.all(followingPromises)).filter(Boolean);
                console.log('Processed following details:', followingDetails);
                setFollowing(followingDetails);

                const initialFollowStatus = {};
                followingDetails.forEach(user => {
                    initialFollowStatus[user.id] = true;
                });
                setFollowStatus(initialFollowStatus);

                setLoading(false);
            } catch (error) {
                console.error('Error in fetchFollowing:', error);
                setError('Error loading following');
                setLoading(false);
            }
        };

        fetchFollowing();
    }, [username, user, isAuthenticated]);

    useEffect(() => {
        const checkFollowStatuses = async () => {
            if (isAuthenticated && user && following.length > 0) {
                const statuses = {};
                for (const followedUser of following) {
                    try {
                        const isFollowing = await checkIsFollowing(user.uid, followedUser.id);
                        statuses[followedUser.id] = isFollowing;
                    } catch (err) {
                        console.error('Error checking follow status:', err);
                    }
                }
                setFollowStatus(statuses);
            }
        };

        checkFollowStatuses();
    }, [isAuthenticated, user, following]);

    const handleFollowToggle = async (followedUserId) => {
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        try {
            await unfollowUser(user.uid, followedUserId);
            
            // Remove the unfollowed user from the following list
            setFollowing(prevFollowing => 
                prevFollowing.filter(followedUser => followedUser.id !== followedUserId)
            );
            
            // Update follow status
            setFollowStatus(prev => ({
                ...prev,
                [followedUserId]: false
            }));
        } catch (error) {
            console.error('Error updating follow status:', error);
        }
    };

    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <h1>{username ? `${username}'s Following` : 'People You Follow'}</h1>
                    {loading ? (
                        <div className="loading-container">
                            <div className="loading-spinner"></div>
                            <div>Loading...</div>
                        </div>
                    ) : error ? (
                        <div className="error-message">{error}</div>
                    ) : (
                        <div className="following-list">
                            {following.length === 0 ? (
                                <div className="no-following">
                                    {username ? 
                                        `${username} isn't following anyone yet` : 
                                        "You aren't following anyone yet"}
                                </div>
                            ) : (
                                following.map(followedUser => (
                                    <div key={followedUser.id} className="following-item">
                                        <Link to={`/user/${followedUser.username}`} className="following-avatar">
                                            <img 
                                                src={followedUser.profileImageUrl || defaultAvatar} 
                                                alt={followedUser.username}
                                                className="avatar-image"
                                            />
                                        </Link>
                                        <div className="following-details">
                                            <Link to={`/user/${followedUser.username}`} className="following-username">
                                                {followedUser.username}
                                            </Link>
                                            {followedUser.name && (
                                                <div className="following-name">{followedUser.name}</div>
                                            )}
                                        </div>
                                        <div className="follow-button-container">
                                            {isAuthenticated ? (
                                                followStatus[followedUser.id] ? (
                                                    <button 
                                                        className="follow-button is-following"
                                                        onClick={() => handleFollowToggle(followedUser.id)}
                                                        onMouseEnter={() => setHoverStatus({ [followedUser.id]: true })}
                                                        onMouseLeave={() => setHoverStatus({ [followedUser.id]: false })}
                                                    >
                                                        {hoverStatus[followedUser.id] ? 'Unfollow' : 'Following'}
                                                    </button>
                                                ) : (
                                                    <button 
                                                        className="follow-button"
                                                        onClick={() => followUser(user.uid, followedUser.id)}
                                                        onMouseEnter={() => setHoverStatus({ [followedUser.id]: true })}
                                                        onMouseLeave={() => setHoverStatus({ [followedUser.id]: false })}
                                                    >
                                                        Follow
                                                    </button>
                                                )
                                            ) : (
                                                <button 
                                                    className="follow-button"
                                                    onClick={() => navigate('/login')}
                                                >
                                                    Follow
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                </div>
            </main>
            <Footer />
        </>
    );
};

export default FollowingPage;
