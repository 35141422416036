import React, { useEffect, useState } from 'react';
import { useAuth } from '../../AuthContext';
import { doc, getDoc, updateDoc, collection, query, where, getDocs, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { FaEdit } from 'react-icons/fa';
import './AccountSettings.css';

const AccountSettings = () => {
    const { user } = useAuth();
    const [username, setUsername] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [newUsername, setNewUsername] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log('User:', user);
        if (user) {
            const fetchUsername = async () => {
                const docRef = doc(db, 'users', user.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    console.log('User Profile:', docSnap.data().userProfile);
                    setUsername(docSnap.data().userProfile.username);
                } else {
                    console.log('No such document!');
                }
            };
            fetchUsername();
        }
    }, [user]);

    const handleEditClick = () => {
        setNewUsername(username);
        setIsEditing(true);
        setError('');
    };

    const checkUsernameAvailability = async (username) => {
        try {
            console.log('Checking username:', username);
            // Get all users
            const usersRef = collection(db, 'users');
            const querySnapshot = await getDocs(usersRef);
            
            // Check if username exists
            let isAvailable = true;
            querySnapshot.forEach((doc) => {
                const userData = doc.data();
                if (userData.userProfile && 
                    userData.userProfile.username === username && 
                    doc.id !== user.uid) {
                    isAvailable = false;
                }
            });
            
            console.log('Username available:', isAvailable);
            return isAvailable;
        } catch (error) {
            console.error('Error checking username availability:', error);
            throw error;
        }
    };

    const handleSave = async () => {
        if (!newUsername.trim()) {
            setError('Username cannot be empty');
            return;
        }

        setLoading(true);
        setError('');

        try {
            console.log('Attempting to update username for user:', user.uid);
            const userRef = doc(db, 'users', user.uid);
            
            // First, get the current document
            const docSnap = await getDoc(userRef);
            
            if (!docSnap.exists()) {
                // If document doesn't exist, create it with full structure
                await setDoc(userRef, {
                    userProfile: {
                        username: newUsername,
                        isDefaultUsername: false,
                        userEmail: user.email
                    }
                });
            } else {
                // Update just the username and isDefaultUsername fields
                await updateDoc(userRef, {
                    'userProfile.username': newUsername,
                    'userProfile.isDefaultUsername': false
                });
            }

            setUsername(newUsername);
            setIsEditing(false);
        } catch (err) {
            console.error('Detailed error:', err);
            setError(`An error occurred while updating username: ${err.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setError('');
    };

    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <h1>Account Settings</h1>
                    <div className="account-settings-content">
                        <h2>General Information</h2>
                        <div className="settings-grid">
                            <div className="field-row">
                                <span className="field-label">Email address:</span>
                                <span className="field-value">{user?.email}</span>
                                <span></span> {/* Empty span for grid alignment */}
                            </div>
                            <div className="field-row">
                                <span className="field-label">Username:</span>
                                <span className="field-value">{username}</span>
                                <button 
                                    onClick={handleEditClick}
                                    className="edit-button"
                                    aria-label="Edit username"
                                >
                                    <FaEdit />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {isEditing && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <h2>Change Username</h2>
                            <p>A username is what you'll go by here, so pick wisely.</p>
                            <input
                                type="text"
                                value={newUsername}
                                onChange={(e) => setNewUsername(e.target.value)}
                                disabled={loading}
                            />
                            {error && <p className="error-message">{error}</p>}
                            <div className="modal-buttons">
                                <button 
                                    onClick={handleSave}
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : 'Save'}
                                </button>
                                <button 
                                    onClick={handleCancel}
                                    disabled={loading}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </main>
            <Footer />
        </>
    );
};

export default AccountSettings;