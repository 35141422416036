import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { db } from '../firebase';
import './SearchBox.css';

const SearchBox = () => {
    const [searchType, setSearchType] = useState('communities');
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const searchRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setShowResults(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const searchContent = async (value) => {
        if (!value.trim()) {
            setResults([]);
            return;
        }

        const searchQuery = value.toLowerCase();
        let searchResults = [];

        try {
            if (searchType === 'users') {
                const usersQuery = query(
                    collection(db, 'users'),
                    where('userProfile.usernameLower', '>=', searchQuery),
                    where('userProfile.usernameLower', '<=', searchQuery + '\uf8ff'),
                    limit(3)
                );
                const userDocs = await getDocs(usersQuery);
                const userData = userDocs.docs.map(doc => ({
                    type: 'user',
                    ...doc.data().userProfile
                }));
                searchResults = [...searchResults, ...userData];
            }

            if (searchType === 'communities') {
                const communitiesQuery = query(
                    collection(db, 'communities'),
                    where('nameToLower', '>=', searchQuery),
                    where('nameToLower', '<=', searchQuery + '\uf8ff'),
                    limit(3)
                );
                const communityDocs = await getDocs(communitiesQuery);
                const communityData = communityDocs.docs.map(doc => ({
                    type: 'community',
                    ...doc.data()
                }));
                searchResults = [...searchResults, ...communityData];
            }

            setResults(searchResults);
        } catch (error) {
            console.error('Search error:', error);
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        setShowResults(true);
        searchContent(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (searchTerm.trim()) {
            navigate(`/search?q=${encodeURIComponent(searchTerm)}&type=${searchType}`);
            setShowResults(false);
        }
    };

    return (
        <div className="search-box" ref={searchRef}>
            <form onSubmit={handleSubmit} className="search-form">
                <div className="search-input-container">
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleInputChange}
                        onFocus={() => setShowResults(true)}
                    />
                    <div className="search-selector">
                        <select 
                            value={searchType}
                            onChange={(e) => setSearchType(e.target.value)}
                            className="search-type-selector"
                        >
                            <option value="communities">Communities</option>
                            <option value="users">Users</option>
                            <option value="posts">Posts</option>
                        </select>
                        <i className="fa-solid fa-angle-down"></i>
                    </div>
                </div>
            </form>
            
            {showResults && results.length > 0 && (
                <div className="search-results">
                    {results.map((result, index) => (
                        <SearchResultItem 
                            key={index}
                            result={result}
                            setShowResults={setShowResults}
                            setSearchTerm={setSearchTerm}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const SearchResultItem = ({ result, setShowResults, setSearchTerm }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (result.type === 'user') {
            navigate(`/user/${result.username}`);
        } else if (result.type === 'community') {
            navigate(`/c/${result.name}`);
        }
        setShowResults(false);
        setSearchTerm('');
    };

    return (
        <div className="search-result-item" onClick={handleClick}>
            {result.type === 'user' && (
                <div className="user-info">
                    <span className="username">@{result.username}</span>
                    {result.fullName && (
                        <span className="full-name">{result.fullName}</span>
                    )}
                </div>
            )}
            {result.type === 'community' && (
                <div className="community-info">
                    <span className="community-name">c/{result.name}</span>
                    <span className="member-count">{result.membersCount || 0} members</span>
                </div>
            )}
        </div>
    );
};

export default SearchBox;
