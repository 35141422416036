import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../AuthContext';
import CommentForm from './CommentForm';
import CommentItem from './CommentItem';
import './Comments.css';
import { DEFAULT_PROFILE_PHOTO } from '../../utils/constants';

const Comments = ({ postId, communityName }) => {
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user } = useAuth();

    useEffect(() => {
        if (!postId || !communityName) return;

        try {
            const commentsRef = collection(db, 'communities', communityName, 'posts', postId, 'comments');
            const q = query(commentsRef, orderBy('createdAt', 'desc'));

            const unsubscribe = onSnapshot(q, async (snapshot) => {
                // Fetch user data for each comment
                const commentsWithUserData = await Promise.all(
                    snapshot.docs.map(async (commentDoc) => {
                        const commentData = commentDoc.data();
                        // Fetch user profile data
                        const userDocRef = doc(db, 'users', commentData.authorId);
                        const userDoc = await getDoc(userDocRef);
                        const userData = userDoc.exists() ? userDoc.data().userProfile : null;
                        
                        return {
                            id: commentDoc.id,
                            ...commentData,
                            createdAt: commentData.createdAt?.toDate(),
                            authorProfilePhoto: userData?.profileImageUrl || DEFAULT_PROFILE_PHOTO,
                            authorUsername: userData?.username
                        };
                    })
                );
                
                console.log('Comments with user data:', commentsWithUserData);
                setComments(commentsWithUserData);
                setLoading(false);
            }, (error) => {
                console.error("Error in comments snapshot:", error);
                setLoading(false);
            });

            return () => unsubscribe();
        } catch (error) {
            console.error("Error setting up comments listener:", error);
            setLoading(false);
        }
    }, [postId, communityName]);

    const handleCommentEdit = async (commentId, newContent) => {
        try {
            const commentRef = doc(
                db,
                'communities',
                communityName,
                'posts',
                postId,
                'comments',
                commentId
            );
            await updateDoc(commentRef, {
                content: newContent,
                lastEdited: new Date()
            });
        } catch (error) {
            console.error('Error updating comment:', error);
        }
    };

    // Organize comments into a tree structure
    const topLevelComments = comments.filter(comment => !comment.parentId);
    const replies = comments.filter(comment => comment.parentId);

    const getCommentsLabel = (count) => {
        if (count === 0) return 'Comments';
        if (count === 1) return '1 Comment';
        return `${count} Comments`;
    };

    if (loading) return <div className="comments-section">Loading comments...</div>;

    return (
        <div className="comments-section">
            <h3>{getCommentsLabel(comments.length)}</h3>
            <CommentForm postId={postId} communityName={communityName} />
            {topLevelComments.length > 0 ? (
                topLevelComments.map(comment => (
                    <CommentItem
                        key={comment.id}
                        comment={comment}
                        communityName={communityName}
                        postId={postId}
                        replies={replies}
                    />
                ))
            ) : (
                <div className="no-comments">No comments yet. Be the first to comment!</div>
            )}
        </div>
    );
};

export default Comments;
